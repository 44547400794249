body { background-color: #ffffff; }
body { color: var(--clr-3591); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: var(--clr-3593);
font-family: 'Nunito Sans';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 25px;

@media #{$medium-up} {
font-size: 30px;

}
@media #{$large-up} {
font-size: 40px;

}
}
h2 {
color: var(--clr-3593);
font-family: 'Nunito Sans';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 25px;

@media #{$medium-up} {
font-size: 30px;

}
}
h3 {
color: var(--clr-3593);
font-family: 'Nunito Sans';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 24px;

}
}
h4 {
color: var(--clr-3593);
font-family: 'Nunito Sans';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 20px;

}
h5 {
color: var(--clr-3593);
font-family: 'Nunito Sans';
font-weight: 600;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
h6 {
font-family: 'Nunito Sans';
font-weight: 300;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 16px;

}
.button {
font-family: 'Nunito Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Arapey';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 22px;

}
}
.me-Quote .quote-author {
font-family: 'Nunito Sans';
font-weight: 300;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
ol,ul {
font-family: 'Nunito Sans';
font-weight: 300;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
color: 30;
font-family: 'Nunito Sans';
font-weight: 300;
font-style: normal;
line-height: 1.3;
letter-spacing: 0;
font-size: 20px;

@media #{$large-up} {
font-size: 24px;

}
}
summary {
font-family: 'Nunito Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
nav.mainmenu > .menu-item > a {
font-family: 'Nunito Sans';
font-weight: 300;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Nunito Sans';
font-weight: 300;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Nunito Sans';
font-weight: 300;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Nunito Sans';
font-weight: 300;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 20px;

}
body {
font-family: 'Nunito Sans';
font-weight: 300;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

}
p { padding-bottom: 1em; }
a {color: var(--clr-3591);}
a:hover {color: var(--clr-3592);}
/* Primary:2 */
.MES2 {
background-color: var(--clr-3593);
&:hover {background-color: var(--clr-3594);}
color: #ffffff;
 }
/* Primary:3 */
.MES3 {
background-color: var(--clr-3593);
color: #ffffff;
border-radius: 8px;
-webkit-transform: translateZ(0);
overflow: hidden;
 }
.MES3 {
background-color: var(--clr-3593);
color: #ffffff;
border-radius: 8px;
-webkit-transform: translateZ(0);
overflow: hidden;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: #ffffff;
 }
 }
a.MEC3 { color: #ffffff;
&:hover { color: var(--clr-3595);}
 }
cite.MEC3{
color: #ffffff;
}
/* Secondary:4 */
.MES4 {
background-color: var(--clr-3596);
color: #ffffff;
&:hover { color: #ffffff;}
 }
/* Secondary:5 */
.MES5 {
background-color: var(--clr-3596);
color: #ffffff;
 }
.MES5 {
background-color: var(--clr-3596);
color: #ffffff;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: #ffffff;
 }
 }
cite.MEC5{
color: #ffffff;
}
/* Alternate:6 */
.MES6 {
background-color: var(--clr-3597);
 }
/* Alternate:7 */
.MES7 {
background-color: var(--clr-3597);
color: #ffffff;
 }
/* Alternate:8 */
.MES8 {
background-color: var(--clr-3597);
color: #ffffff;
 }
.MES8 {
background-color: var(--clr-3597);
color: #ffffff;
h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: #ffffff;
 }
 }
cite.MEC8{
color: #ffffff;
}
/* Alternate:9 */
.MES9 {
background-color: var(--clr-3597);
color: #ffffff;
 }
.MES9 {
background-color: var(--clr-3597);
color: #ffffff;
h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: #ffffff;
 }
 }
cite.MEC9{
color: #ffffff;
}
/* Light:10 */
.MES10 {
background-color: var(--clr-3598);
color: #ffffff;
 }
/* Light:11 */
.MES11 {
background-color: var(--clr-3598);
color: #ffffff;
 }
.MES11 {
background-color: var(--clr-3598);
color: #ffffff;
h1.MEC11, h2.MEC11, h3.MEC11, h4.MEC11, h5.MEC11, h6.MEC11 { color: #ffffff;
 }
 }
cite.MEC11{
color: #ffffff;
}
/* Shade 1:12 */
.MES12 {
background-color: var(--clr-3599);
color: #ffffff;
 }
/* Shade 1:13 */
.MES13 {
background-color: var(--clr-3599);
color: #ffffff;
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
 }
.MES13 {
background-color: var(--clr-3599);
color: #ffffff;
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: #ffffff;
 }
 }
cite.MEC13{
color: #ffffff;
}
/* Copyright panel:14 */
.MES14 {
font-size: 14.4px;
 }
.MES14 {
font-size: 14.4px;
 }
cite.MEC14{
font-size: 14.4px;
}
/* News Grid:16 */
.MES16 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-3595);}
padding: 15px;

@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-3598);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES16 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-3595);}
padding: 15px;

@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-3598);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Main Menu:17 */
nav.me-Menu.MES17 {
& .menu-item.MEC17, & .menu-item.MEC17 > div.MEC17{ & > a.MEC17{color: var(--clr-3593);
text-transform: none;
}
 &:hover > a.MEC17{color: var(--clr-3596);
}
 }
&.horizontal > .menu-item.MEC17 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC17 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC17 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC17 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px;}

& .sub-menu .menu-item a{padding: 15px 20px;}



&.horizontal .menu-item.MEC17:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid var(--clr-3602-flat);}}
&.vertical .menu-item.MEC17:hover {& > .pointer-wrap > .pointer{border-right: 10px solid var(--clr-3602-flat);
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC17{background-color: var(--clr-3602); &:hover {background-color: 2;}
}
& .menu-item.MEC17, & .menu-item.MEC17 > div.MEC17{ & > a.MEC17{color: var(--clr-3593);
}
 &:hover > a.MEC17{color: 6;
}
 }

}
}
 }
/* Paypal button:18 */
.MES18 {
background-color: var(--clr-3594);
color: #ffffff;
 }
/* Home slider:19 */
.MES19 {
& .slider-arrow {color: var(--clr-3603);
font-size: 40px;
@media #{$medium-up} {
font-size: 40px;
};
@media #{$large-up} {
font-size: 60px;
};
}& .slider-arrow:hover{color: var(--clr-3600);
}& .slick-dots {text-align: center;
 button {margin: 0 1px;
;border-color: 16;
border-radius: 50%;
border-style:solid;
border-width:2px;
@media #{$medium-up} {
border-width: 2px;
};
@media #{$large-up} {
border-width: 2px;};
&:hover{border-color: 11;}
background-color: 2;
background-clip: padding-box;
&:hover {background-color: 11;}
width:3px;
height:3px;
@media #{$medium-up} {
width:3px;
height:3px;
};
@media #{$large-up} {
width:3px;
height:3px;
};
&:hover{background-color: 11;}
}}
 }
/* Responsive Menu:20 */
nav.responsive-menu {
.menu-item.MEC20{background-color: var(--clr-3596);}
& .menu-item.MEC20, & .menu-item.MEC20 > div.menu-item-wrap{ & > a.MEC20, & > i{color: #ffffff;
text-transform: capitalize;
text-align: center;
}
  }
& .menu-item.MEC20 { border:0;

 border-bottom-width: 1px; }
& .sub-menu{ .sub-menu {}}

 }
/* login buttons:21 */
.MES21 {
background-color: var(--clr-3597);
&:hover {background-color: var(--clr-3600);}
color: #ffffff;
&:hover { color: var(--clr-3593);}
border-radius: 100px;
padding: 10px 15px;

@media #{$large-up} {
padding: 20px 30px;

}
border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Copyright panel grey:22 */
.MES22 {
background-color: var(--clr-3598);
font-size: 14.4px;
 }
.MES22 {
background-color: var(--clr-3598);
font-size: 14.4px;
 }
cite.MEC22{
font-size: 14.4px;
}
/* carousel padding:23 */
.MES23 {
padding: 10px;

 }
.MES23 {
padding: 10px;

 }
/* Carousel grey pad:24 */
.MES24 {
background-color: var(--clr-3597);
padding: 20px 15px;

@media #{$large-up} {
padding: 20px;

}
 }
.MES24 {
background-color: var(--clr-3597);
padding: 20px 15px;

@media #{$large-up} {
padding: 20px;

}
 }
/* border line:25 */
.MES25 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-3595) transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Top Strip Panel:26 */
.MES26 {
background-color: #ffffff;
color: var(--clr-3593);
font-size: 18px;
@media #{$large-up} {
font-size: 14.4px;
};
padding: 5px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-3598) transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES26 {
background-color: #ffffff;
color: var(--clr-3593);
font-size: 18px;
@media #{$large-up} {
font-size: 14.4px;
};
padding: 5px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-3598) transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC26, h2.MEC26, h3.MEC26, h4.MEC26, h5.MEC26, h6.MEC26 { color: var(--clr-3593);
 }
h1.MEC26 { @media #{$medium-up} { font-size: 24px; }; }
h1.MEC26 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC26 { @media #{$medium-up} { font-size: 24px; }; }
h2.MEC26 { @media #{$large-up} { font-size: 30px; }; }
h3.MEC26 { @media #{$medium-up} { font-size: 19.2px; }; }
h3.MEC26 { @media #{$large-up} { font-size: 24px; }; }
h4.MEC26 { @media #{$medium-up} { font-size: 16px; }; }
h4.MEC26 { @media #{$large-up} { font-size: 20px; }; }
h5.MEC26 { @media #{$medium-up} { font-size: 14.4px; }; }
h5.MEC26 { @media #{$large-up} { font-size: 18px; }; }
h6.MEC26 { @media #{$medium-up} { font-size: 12.8px; }; }
h6.MEC26 { @media #{$large-up} { font-size: 16px; }; }
 }
a.MEC26 { color: var(--clr-3593);
&:hover { color: var(--clr-3592);}
 }
cite.MEC26{
color: var(--clr-3593);
font-size: 18px;
@media #{$large-up} {
font-size: 14.4px;
};
}
/* White BG Transparent:27 */
.MES27 {
background-color: var(--clr-3604);
 }
.MES27 {
background-color: var(--clr-3604);
 }
/* Popup form Window:28 */
.MES28 {
background-color: #ffffff;
padding: 20px;

border-width: 5px;
border-style: solid;
border-color: var(--clr-3595);
 }
.MES28 {
background-color: #ffffff;
padding: 20px;

border-width: 5px;
border-style: solid;
border-color: var(--clr-3595);
 }
/* Exhibition rollover:29 */
.MES29 {
background-color: #ffffff;
&:hover, &.hover { background-color: var(--clr-3597);}
 }
.MES29 {
background-color: #ffffff;
&:hover, &.hover { background-color: var(--clr-3597);}
 }
/* SOLD Panel Red text:30 */
.MES30 {
color: var(--clr-3598);
 }
.MES30 {
color: var(--clr-3598);
h1.MEC30, h2.MEC30, h3.MEC30, h4.MEC30, h5.MEC30, h6.MEC30 { color: var(--clr-3599);
 }
 }
cite.MEC30{
color: var(--clr-3598);
}
/* Shade 2:31 */
.MES31 {
background-color: var(--clr-3601);
color: var(--clr-3591);
 }
/* Shade 2:32 */
.MES32 {
background-color: var(--clr-3600);
color: var(--clr-3591);
 }
.MES32 {
background-color: var(--clr-3600);
color: var(--clr-3591);
 }
cite.MEC32{
color: var(--clr-3591);
}
/* Shade 3:33 */
.MES33 {
background-color: var(--clr-3600);
color: var(--clr-3591);
 }
/* Shade 3:34 */
.MES34 {
background-color: var(--clr-3600);
color: var(--clr-3597);
 }
.MES34 {
background-color: var(--clr-3600);
color: var(--clr-3597);
h1.MEC34, h2.MEC34, h3.MEC34, h4.MEC34, h5.MEC34, h6.MEC34 { color: var(--clr-3593);
 }
 }
cite.MEC34{
color: var(--clr-3597);
}
/* Banner Text white Bg:36 */
.MES36 {
color: var(--clr-3597);
 }
.MES36 {
color: var(--clr-3597);
h1.MEC36, h2.MEC36, h3.MEC36, h4.MEC36, h5.MEC36, h6.MEC36 { color: var(--clr-3593);
 }
 }
cite.MEC36{
color: var(--clr-3597);
}
/* Lozenge Banner:37 */
.MES37 {
background-color: var(--clr-3604);
color: var(--clr-3597);
border-radius: 0 100px 100px 0;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px 30px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
.MES37 {
background-color: var(--clr-3604);
color: var(--clr-3597);
border-radius: 0 100px 100px 0;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px 30px;

border-width: 1px;
border-style: solid;
border-color: transparent;
h1.MEC37, h2.MEC37, h3.MEC37, h4.MEC37, h5.MEC37, h6.MEC37 { color: var(--clr-3593);
 }
 }
cite.MEC37{
color: var(--clr-3597);
}
/* Image Circle:38 */
.MES38 {
border-radius: 100%;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: transparent;
 }
.MES38 {
border-radius: 100%;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Gradient bar:39 */
.MES39 {
background-color: var(--clr-3596);
min-height:8px;
@media #{$medium-up} {
min-height: 8px;};
@media #{$large-up} {
min-height: 8px;};
border-radius: 100px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES39 {
background-color: var(--clr-3596);
min-height:8px;
@media #{$medium-up} {
min-height: 8px;};
@media #{$large-up} {
min-height: 8px;};
border-radius: 100px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Gradient bar2:40 */
.MES40 {
background-color: var(--clr-3596);
color: #ffffff;
min-height:80px;
@media #{$medium-up} {
min-height: 100px;};
@media #{$large-up} {
min-height: 120px;};
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px;

border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES40 {
background-color: var(--clr-3596);
color: #ffffff;
min-height:80px;
@media #{$medium-up} {
min-height: 100px;};
@media #{$large-up} {
min-height: 120px;};
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px;

border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC40, h2.MEC40, h3.MEC40, h4.MEC40, h5.MEC40, h6.MEC40 { color: #ffffff;
 }
 }
cite.MEC40{
color: #ffffff;
}
/* White Circle:41 */
.MES41 {
background-color: #ffffff;
border-radius: 50px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 10px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
.MES41 {
background-color: #ffffff;
border-radius: 50px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 10px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* :42 */
.MES42 {
background-color: var(--clr-3593);
& > .underlay, & > .inner-overlay { opacity: 0.3 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://rhythmbio.com/img/956/208');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
 }
.MES42 {
background-color: var(--clr-3593);
& > .underlay, & > .inner-overlay { opacity: 0.3 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://rhythmbio.com/img/956/208');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
h1.MEC42, h2.MEC42, h3.MEC42, h4.MEC42, h5.MEC42, h6.MEC42 { color: #ffffff;
 }
 }
a.MEC42 { color: var(--clr-3600);
 }
cite.MEC42{
color: #ffffff;
}
/* Cyan losenge left:43 */
.MES43 {
background-color: var(--clr-3596);
color: #ffffff;
border-radius: 2000px 0 0 2000px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px;

@media #{$large-up} {
padding: 50px 20px;

}
border-width: 1px;
border-style: solid;
border-color: transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES43 {
background-color: var(--clr-3596);
color: #ffffff;
border-radius: 2000px 0 0 2000px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px;

@media #{$large-up} {
padding: 50px 20px;

}
border-width: 1px;
border-style: solid;
border-color: transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC43, h2.MEC43, h3.MEC43, h4.MEC43, h5.MEC43, h6.MEC43 { color: #ffffff;
 }
 }
cite.MEC43{
color: #ffffff;
}
/* :44 */
details.MES44 {
& > summary{background-color: #001e60;
}
& > summary {border-radius: 20px;}
& > summary{padding: 10px 15px;}

& > summary { color: #ffffff;
&:hover {color: #ffffff;}
 }
 }
/* Blue buttons:45 */
.MES45 {
background-color: var(--clr-3593);
&:hover {background-color: var(--clr-3596);}
color: #ffffff;
&:hover { color: #ffffff;}
border-radius: 100px;
padding: 10px 15px;

@media #{$large-up} {
padding: 20px 30px;

}
border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Grey losenge left:46 */
.MES46 {
background-color: var(--clr-3600);
color: var(--clr-3598);
border-radius: 2000px 0 0 2000px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px;

@media #{$large-up} {
padding: 50px 20px;

}
border-width: 1px;
border-style: solid;
border-color: transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES46 {
background-color: var(--clr-3600);
color: var(--clr-3598);
border-radius: 2000px 0 0 2000px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px;

@media #{$large-up} {
padding: 50px 20px;

}
border-width: 1px;
border-style: solid;
border-color: transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC46, h2.MEC46, h3.MEC46, h4.MEC46, h5.MEC46, h6.MEC46 { color: var(--clr-3593);
 }
 }
cite.MEC46{
color: var(--clr-3598);
}
/* Blue losenge left:48 */
.MES48 {
background-color: var(--clr-3593);
color: #ffffff;
border-radius: 2000px 0 0 2000px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px;

@media #{$large-up} {
padding: 50px 20px;

}
border-width: 1px;
border-style: solid;
border-color: transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES48 {
background-color: var(--clr-3593);
color: #ffffff;
border-radius: 2000px 0 0 2000px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px;

@media #{$large-up} {
padding: 50px 20px;

}
border-width: 1px;
border-style: solid;
border-color: transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC48, h2.MEC48, h3.MEC48, h4.MEC48, h5.MEC48, h6.MEC48 { color: #ffffff;
 }
 }
cite.MEC48{
color: #ffffff;
}
/* DropStyle:49 */
.MES49 {
background-color: var(--clr-3604);
color: var(--clr-3598);
padding: 20px;

border-width: 3px;
border-style: solid;
border-color: #ffffff;
&:hover { border-color: var(--clr-3600); }
border-top-style: dashed;
border-right-style: dashed;
border-bottom-style: dashed;
border-left-style: dashed;
 }
.MES49 {
background-color: var(--clr-3604);
color: var(--clr-3598);
padding: 20px;

border-width: 3px;
border-style: solid;
border-color: #ffffff;
&:hover { border-color: var(--clr-3600); }
border-top-style: dashed;
border-right-style: dashed;
border-bottom-style: dashed;
border-left-style: dashed;
 }
cite.MEC49{
color: var(--clr-3598);
}
/* List file style:50 */
.MES50 {
background-color: var(--clr-3604);
padding: 10px 0;

border-width: 1px;
border-style: solid;
border-color: #ffffff;
 }
.MES50 {
background-color: var(--clr-3604);
padding: 10px 0;

border-width: 1px;
border-style: solid;
border-color: #ffffff;
 }
/* Search Panel:51 */
.MES51 {
background-color: var(--clr-3600);
color: var(--clr-3598);
font-size: 14.4px;
border-radius: 2000px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 5px;

border-width: 1px;
border-style: solid;
border-color: transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES51 {
background-color: var(--clr-3600);
color: var(--clr-3598);
font-size: 14.4px;
border-radius: 2000px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 5px;

border-width: 1px;
border-style: solid;
border-color: transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC51, h2.MEC51, h3.MEC51, h4.MEC51, h5.MEC51, h6.MEC51 { color: var(--clr-3593);
 }
 }
cite.MEC51{
color: var(--clr-3598);
font-size: 14.4px;
}
/* Graph panel:52 */
.MES52 {
background-color: var(--clr-3596);
color: #ffffff;
border-radius: 2000px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 2px;

border-width: 1px;
border-style: solid;
border-color: transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES52 {
background-color: var(--clr-3596);
color: #ffffff;
border-radius: 2000px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 2px;

border-width: 1px;
border-style: solid;
border-color: transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC52, h2.MEC52, h3.MEC52, h4.MEC52, h5.MEC52, h6.MEC52 { color: #ffffff;
 }
 }
cite.MEC52{
color: #ffffff;
}
/* Heading losenge panel:53 */
.MES53 {
background-color: var(--clr-3597);
color: #ffffff;
border-radius: 2000px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 2px;

border-width: 1px;
border-style: solid;
border-color: transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES53 {
background-color: var(--clr-3597);
color: #ffffff;
border-radius: 2000px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 2px;

border-width: 1px;
border-style: solid;
border-color: transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC53, h2.MEC53, h3.MEC53, h4.MEC53, h5.MEC53, h6.MEC53 { color: #ffffff;
 }
 }
cite.MEC53{
color: #ffffff;
}
/* Timeline Item:54 */
.MES54 {
color: #ffffff;
font-size: 14.4px;
 }
.MES54 {
color: #ffffff;
font-size: 14.4px;
h1.MEC54, h2.MEC54, h3.MEC54, h4.MEC54, h5.MEC54, h6.MEC54 { color: #ffffff;
 }
 }
cite.MEC54{
color: #ffffff;
font-size: 14.4px;
}
/* White losenge left:55 */
.MES55 {
background-color: #ffffff;
color: var(--clr-3598);
border-radius: 0 2000px 2000px 0;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px;

@media #{$large-up} {
padding: 50px 20px;

}
border-width: 1px;
border-style: solid;
border-color: transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES55 {
background-color: #ffffff;
color: var(--clr-3598);
border-radius: 0 2000px 2000px 0;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px;

@media #{$large-up} {
padding: 50px 20px;

}
border-width: 1px;
border-style: solid;
border-color: transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC55, h2.MEC55, h3.MEC55, h4.MEC55, h5.MEC55, h6.MEC55 { color: var(--clr-3593);
 }
 }
cite.MEC55{
color: var(--clr-3598);
}
/* White Losenge panel:56 */
.MES56 {
background-color: #ffffff;
color: var(--clr-3593);
border-radius: 2000px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 2px;

border-width: 1px;
border-style: solid;
border-color: transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES56 {
background-color: #ffffff;
color: var(--clr-3593);
border-radius: 2000px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 2px;

border-width: 1px;
border-style: solid;
border-color: transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC56, h2.MEC56, h3.MEC56, h4.MEC56, h5.MEC56, h6.MEC56 { color: var(--clr-3593);
 }
 }
cite.MEC56{
color: var(--clr-3593);
}
/* Green Graph panel:57 */
.MES57 {
background-color: var(--clr-3599);
color: #ffffff;
border-radius: 2000px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 2px;

border-width: 1px;
border-style: solid;
border-color: transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES57 {
background-color: var(--clr-3599);
color: #ffffff;
border-radius: 2000px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 2px;

border-width: 1px;
border-style: solid;
border-color: transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC57, h2.MEC57, h3.MEC57, h4.MEC57, h5.MEC57, h6.MEC57 { color: #ffffff;
 }
 }
cite.MEC57{
color: #ffffff;
}
/* Footer Menu:58 */
nav.me-Menu.MES58 {
& .menu-item.MEC58, & .menu-item.MEC58 > div.MEC58{ & > a.MEC58{color: #ffffff;
text-transform: capitalize;
}
 &:hover > a.MEC58{color: var(--clr-3600);
}
 }
&.horizontal > .menu-item.MEC58 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC58 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC58 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC58 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 0;}

& .sub-menu .menu-item a{padding: 5px 10px;}


&.horizontal .menu-item.MEC58:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid var(--clr-3602-flat);}}
&.vertical .menu-item.MEC58:hover {& > .pointer-wrap > .pointer{border-right: 10px solid var(--clr-3602-flat);
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC58{background-color: var(--clr-3602); &:hover {background-color: 2;}
}
& .menu-item.MEC58, & .menu-item.MEC58 > div.MEC58{ & > a.MEC58{color: var(--clr-3593);
}
 &:hover > a.MEC58{color: 6;
}
 }

}
}
 }
/* Green button:59 */
.MES59 {
background-color: var(--clr-3599);
&:hover {background-color: var(--clr-3597);}
color: #ffffff;
&:hover { color: #ffffff;}
border-radius: 100px;
padding: 10px 15px;

@media #{$large-up} {
padding: 20px 30px;

}
border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Grey Colostat panel:60 */
.MES60 {
background-color: var(--clr-3600);
color: var(--clr-3591);
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 2px;
border-style: solid;
border-color: var(--clr-3593);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES60 {
background-color: var(--clr-3600);
color: var(--clr-3591);
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 2px;
border-style: solid;
border-color: var(--clr-3593);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC60, h2.MEC60, h3.MEC60, h4.MEC60, h5.MEC60, h6.MEC60 { color: var(--clr-3599);
 }
 }
cite.MEC60{
color: var(--clr-3591);
}
/* Aqua buttons:61 */
.MES61 {
background-color: var(--clr-3596);
&:hover {background-color: var(--clr-3597);}
color: #ffffff;
&:hover { color: var(--clr-3593);}
border-radius: 100px;
padding: 10px 15px;

@media #{$large-up} {
padding: 20px 30px;

}
border-width: 2px;
border-style: solid;
border-color: var(--clr-3596);
&:hover { border-color: #ffffff; }
 }
/* Page Banner #1:62 */
.MES62 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://rhythmbio.com/img/3424/984');
background-position: center right;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
 }
.MES62 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://rhythmbio.com/img/3424/984');
background-position: center right;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
h1.MEC62, h2.MEC62, h3.MEC62, h4.MEC62, h5.MEC62, h6.MEC62 { color: #ffffff;
 }
 }
cite.MEC62{
color: #ffffff;
}
/* Page Banner #2:63 */
.MES63 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://rhythmbio.com/img/3424/984');
background-position: center right;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
 }
.MES63 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://rhythmbio.com/img/3424/984');
background-position: center right;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
h1.MEC63, h2.MEC63, h3.MEC63, h4.MEC63, h5.MEC63, h6.MEC63 { color: #ffffff;
 }
 }
cite.MEC63{
color: #ffffff;
}
/* Footer text links panel:64 */
.MES64 {
font-size: 14.4px;
 }
.MES64 {
font-size: 14.4px;
 }
a.MEC64 { color: #ffffff;
&:hover { color: var(--clr-3600);}
 }
cite.MEC64{
font-size: 14.4px;
}
/* Panel Right border white:65 */
.MES65 {
color: #ffffff;
@media #{$medium-up} {
border-width: 0 1px 0 0;
};
border-style: solid;
border-color: transparent #ffffff transparent transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES65 {
color: #ffffff;
@media #{$medium-up} {
border-width: 0 1px 0 0;
};
border-style: solid;
border-color: transparent #ffffff transparent transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
cite.MEC65{
color: #ffffff;
}
/* Light Grey:66 */
.MES66 {
background-color: var(--clr-3595);
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
 }
.MES66 {
background-color: var(--clr-3595);
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
 }
/* Graph panel navy:67 */
.MES67 {
background-color: var(--clr-3593);
color: #ffffff;
border-radius: 2000px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 2px;

border-width: 1px;
border-style: solid;
border-color: transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES67 {
background-color: var(--clr-3593);
color: #ffffff;
border-radius: 2000px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 2px;

border-width: 1px;
border-style: solid;
border-color: transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC67, h2.MEC67, h3.MEC67, h4.MEC67, h5.MEC67, h6.MEC67 { color: #ffffff;
 }
 }
cite.MEC67{
color: #ffffff;
}
/* Graph panel teal:68 */
.MES68 {
background-color: var(--clr-3605);
color: #ffffff;
border-radius: 2000px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 2px;

border-width: 1px;
border-style: solid;
border-color: transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES68 {
background-color: var(--clr-3605);
color: #ffffff;
border-radius: 2000px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 2px;

border-width: 1px;
border-style: solid;
border-color: transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC68, h2.MEC68, h3.MEC68, h4.MEC68, h5.MEC68, h6.MEC68 { color: #ffffff;
 }
 }
cite.MEC68{
color: #ffffff;
}
/* Panel border 1 px:69 */
.MES69 {
padding: 10px 0;

border-width: 1px;
border-style: solid;
border-color: #ffffff;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES69 {
padding: 10px 0;

border-width: 1px;
border-style: solid;
border-color: #ffffff;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Grey losenge left:70 */
.MES70 {
background-color: var(--clr-3600);
color: var(--clr-3598);
border-radius: 0 2000px 2000px 0;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px;

@media #{$large-up} {
padding: 50px 20px;

}
border-width: 1px;
border-style: solid;
border-color: transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES70 {
background-color: var(--clr-3600);
color: var(--clr-3598);
border-radius: 0 2000px 2000px 0;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px;

@media #{$large-up} {
padding: 50px 20px;

}
border-width: 1px;
border-style: solid;
border-color: transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC70, h2.MEC70, h3.MEC70, h4.MEC70, h5.MEC70, h6.MEC70 { color: var(--clr-3593);
 }
 }
cite.MEC70{
color: var(--clr-3598);
}
/* Blue line:71 */
.MES71 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-3596) transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Page Accordion:72 */
details.MES72 {
 }
/* :73 */
.MES73 {
background-color: #00bce5b9;
 }
.MES73 {
background-color: #00bce5b9;
 }
/* :74 */
.MES74 {
background-color: #dcdcdc;
color: #000080;
font-size: 18px;
@media #{$medium-up} {
font-size: 19.8px;
};
@media #{$large-up} {
font-size: 21.6px;
};
border-radius: 8px;
 }
/* :75 */
.MES75 {
background-color: #001e60;
color: #ffffff;
 }
.MES75 {
background-color: #001e60;
color: #ffffff;
h1.MEC75, h2.MEC75, h3.MEC75, h4.MEC75, h5.MEC75, h6.MEC75 { color: #ffffff;
 }
 }
cite.MEC75{
color: #ffffff;
}
/* White outline buttons:76 */
.MES76 {
background-color: var(--clr-3596);
color: #ffffff;
&:hover { color: #dcdcdc;}
border-radius: 100px;
padding: 10px 15px;

@media #{$large-up} {
padding: 20px 30px;

}
border-width: 2px;
border-style: solid;
border-color: #ffffff;
&:hover { border-color: #ffffff; }
 }
